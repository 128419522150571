import { calendar } from './modules/calendar';
import { fileCheck } from './modules/fileCheck';
import { header } from './modules/header';
header();
fileCheck();
calendar();

// if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)) {
//   removeEleSp();
//   setTimeout(() => {
//     alert('apollonはスマホ非対応です！');
//   }, 100);
// }

// const userAgent = window.navigator.userAgent.toLowerCase();

// if (userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
//   removeEle();
//   setTimeout(() => {
//     alert('IEは非推奨なので使わないでね！');
//   }, 100);
// }

function removeEle() {
  const body = document.querySelector('body');
  const main = document.querySelector('main');
  body.classList.remove('c-bgGrad--yellow');
  main.remove();

  if (document.querySelector('header')) {
    const header = document.querySelector('header');
    const footer = document.querySelector('footer');
    const collision = document.querySelector('.c-collisionCont');

    header.remove();
    footer.remove();
    collision.remove();
  }

  const element = document.createElement('div');
  element.classList.add('c-not');
  element.innerHTML = '<div class="c-not_cont"><div class="c-not_dame"><img src="/assets/img/common/dame_woman.png" alt=""></div><div class="c-not_circle"></div><div class="c-not_dame"><img src="/assets/img/common/dame_man.png" alt=""></div></div><a href="microsoft-edge:http://apollon.dev-envi-2020.work/login.html" class="c-not_link c-text_middle c-text_color--orange">Edgeで開く</a>';
  body.appendChild(element);
}

function removeEleSp() {
  const body = document.querySelector('body');
  const main = document.querySelector('main');
  body.classList.remove('c-bgGrad--yellow');
  main.remove();

  if (document.querySelector('header')) {
    const header = document.querySelector('header');
    const footer = document.querySelector('footer');
    const collision = document.querySelector('.c-collisionCont');

    header.remove();
    footer.remove();
    collision.remove();
  }

  const element = document.createElement('div');
  element.classList.add('c-not');
  element.innerHTML = '<div class="c-not_cont"><div class="c-not_dame"><img src="/assets/img/common/dame_woman.png" alt=""></div><div class="c-not_circle--sp"></div><div class="c-not_dame"><img src="/assets/img/common/dame_man.png" alt=""></div></div>';
  body.appendChild(element);
}
