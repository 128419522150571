export function header() {
  const hamburger = document.querySelector('.js-hamburger');
  const headerIntro = document.querySelector('.js-headerIntro');
  const body = document.querySelector('body');

  const headerProfile = document.querySelector('.js-headerProfile');

  if (hamburger) {
    hamburger.addEventListener('click', () => {
      if (hamburger.classList.contains('is-active')) {
        body.classList.remove('is-move');
        hamburger.classList.remove('is-active');
        headerIntro.classList.remove('is-view');
      } else {
        body.classList.add('is-move');
        hamburger.classList.add('is-active');
        headerIntro.classList.add('is-view');
      }
    });

    headerProfile.addEventListener('mouseover', () => {
      headerProfile.classList.add('is-active');
    });

    headerProfile.addEventListener('mouseout', () => {
      headerProfile.classList.remove('is-active');
    });
  }
}
