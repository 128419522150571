export function fileCheck() {
  if (document.querySelector('.js-file')) {
    const fileEle = document.getElementsByClassName('js-file');
    const length = fileEle.length;
    for (let i = 0; i < length; i++) {
      fileEle[i].onchange = function () {
        const file = this.files[0].name;
        const fileInner = document.querySelector('.js-fileInner');
        const fileLabel = document.querySelector('.js-fileLabel');
        if (!this.parentNode.classList.contains("is-changed")) {
          const span = document.createElement("span");
          span.className = "c-formFile_name";
          // fileInner.appendChild(span);
          // fileLabel.classList.add("is-changed");
          this.parentNode.parentNode.appendChild(span);
          this.parentNode.classList.add("is-changed");
        }
        // fileLabel.nextElementSibling.innerHTML = file;
        const fileName = document.querySelector('.c-formFile_name');
        this.parentNode.nextElementSibling.innerHTML = file;
      };
    }
  }
}